<!--
List all videos in S2Videos collection
Display as a b-table
For each video add the ability to create a unique link to the video
Show 2 fields: 1) name 2) id and a button create link object
When the button is clicked, create an object in S2Links collection with the following properties:
1) id: the id of the link
2) name: the name of the video
-->
<template>
  <div>
    <div v-if="$debug.isOn">
      <debug-obj :objData="$store.state.accountInfo" />
    </div>
    <b-tabs>
      <b-tab title="Slides" class="m-2" v-if="$store.state.accountInfo?.settings?.createSlides">
        <CreateSlides />
      </b-tab>
      <b-tab title="Embed" class="m-2" v-if="$store.state.accountInfo?.settings?.createEmbed">
        <CreateEmbed />
      </b-tab>
      <b-tab title="Series" class="m-2" v-if="$store.state.accountInfo?.settings?.createSeries">
        <CreateSeries :assets="assets" />
      </b-tab>
      <b-tab title="Assets" class="m-2" active>
        <ListAssets :assets="assets" />
      </b-tab>
      <b-tab title="Links" class="m-2">
        <h2 class="mt-2">Links</h2>
        <b-table striped hover 
          :items="links"
          :fields="linkFields"
          :sort-compare="tableSortCompare"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #cell(id)="data">
          <div class="d-flex flex-grow">
            <div class="form-inline sub-mr-2" v-if="!data.item.expired">
              <sharable :config="{hideContent:true}" :content="`${getURLRoot()}/vid/watch/${data.value}`"/>
              <b-link :href="'/vid/watch/' + data.value" target="_blank">{{ data.value }}</b-link>
            </div>
            <div class="form-inline sub-mr-2" v-else>
              {{ data.value }}&nbsp;
              <font-awesome-icon icon="exclamation-triangle" /> Expired
            </div>
            <b-button class="ml-auto" @click="editLink(data.item)">
              <font-awesome-icon icon="cog" />
            </b-button>
          </div>
          </template>
        </b-table>
        <!-- link edit -->
        <b-modal id="modal-edit-link" centered 
          :title="link.name"
          ok-title="Save" ok-variant="primary"
          cancel-title="Cancel" cancel-variant="secondary"
          @ok="updateObject('S2Links', link, {expired:link.expired})">
          <b-form-group label="Settings">
            <b-form-checkbox v-model="link.expired">Expired</b-form-checkbox>
          </b-form-group>
        </b-modal>
      </b-tab>
      <b-tab title="Visits" class="m-2">
        <h2 class="mt-2">Visits</h2>
        <b-table striped hover 
          :items="visits" 
          :fields="fields" 
          :sort-compare="tableSortCompare"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #cell(visitor)="data">
            {{ data.item.visitor }}
            {{ data.item.authenticated ? " 🧑 you" : " 👤 unknown" }}
          </template>
          <template #cell(completion)="data">
            {{ (data.item.completion * 100).toFixed(2) }}%
          </template>
          <template #cell(device)="data">
            {{ data.item.device?.browser }}
          </template>
          <template #cell(created)="data">
            {{ data.item.created?.toDate() | formatDate }}
          </template>
          <template #cell(location)="data">
            <template v-if="data.item.location">
              {{ data.item.location.city }}, {{ data.item.location.region }}, {{ data.item.location.country_name }}
            </template>
            <template v-else>
              Unknown
            </template>
            <template v-if="data.item.ip">
              ({{ data.item.ip }})
            </template>
          </template>
          <template #cell(note)="data">
            <editable :text="data.item.note" @change="(v) => updateObject('S2Visits', data.item, {note: v})">
              {{ data.item.note }}&nbsp;
            </editable>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('List');
import { db } from '@/services/db';
import { getURLRoot } from '@/services/utils';
import { removeUndefined } from '../../services/utils';

export default {
  components: {
    CreateEmbed: () => import('./CreateEmbed.vue'),
    CreateSlides: () => import('./CreateSlides.vue'),
    CreateSeries: () => import('./CreateSeries.vue'),
    ListAssets: () => import('./ListAssets.vue'),
    Sharable: () => import('@/components/shareable.vue'),
    Editable: () => import('@/components/editable.vue'),
  },
  data() {
    return {
      selectedVideo: null,
      link: {},

      assets: [],
      links: [],
      visits: [],

      // Links table
      linkFields: [
        { key: 'name', label: "Video", sortable: true },
        { key: 'id', label: "Link", sortable: true },
        { key: 'visitor', sortable: true },
      ],

      // Visits table
      sortBy: 'created',
      sortDesc: true,
      fields: [
        { key: 'videoName', label:"Video", sortable: true },
        { key: 'visitor', sortable: true },
        { key: 'created', sortable: true },
        { key: 'completion', sortable: true },
        { key: 'device', sortable: false },
        { key: 'location', sortable: false },
        { key: 'note', sortable: false },
      ],
    };
  },
  mounted() {
    log.log("mounted");
    this.$bind('assets', db.collection('S2Videos').where('ownerId', '==', this.$store.account.uid));
    this.$bind('links', db.collection('S2Links').where('ownerId', '==', this.$store.account.uid));
    this.$bind('visits', db.collection('S2Visits').where('ownerId', '==', this.$store.account.uid));
  },
  methods: {
    tableSortCompare(a, b, key) {
      if (key === 'created')
        return a[key]?.toDate() - b[key]?.toDate();
      else
        return false;
    },    
    getURLRoot,
    updateObject(colPath, obj, data) {
      log.log("updateObject", colPath, obj.id, data );
      db.collection(colPath).doc(obj.id).update(removeUndefined(data));
    },
    editLink(link) {
      log.log("editLink", link);
      this.link = {...link};
      this.$bvModal.show('modal-edit-link');
    },
  }
}
</script>
